@mixin fluid-type($min-vw, $max-vw, $min-value, $max-value) {
  $factor: 1 / ($max-vw - $min-vw) * ($max-value - $min-value);
  $calc-value: unquote('#{ $min-value - ($min-vw * $factor) } + #{ 100vw * $factor }');

  font-size: $min-value;
  font-size: clamp(#{if($min-value > $max-value, $max-value, $min-value)}, #{$calc-value}, #{if($min-value > $max-value, $min-value, $max-value)});
}
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&display=swap');

$black: #000000;
$color-dark: scale-color($black, $lightness: -30%);
$transition: 120ms all 70ms ease-out;

$primary-color: #73002E;
$secondary-color: #910039;
$default-color: #383838;
$white: #ffffff;
.column-middle-flex {
.dropdown__title {
    background-color: transparent;
    border: none;
    font-family: inherit;
  }

nav {
    background-color: $secondary-color;
    padding: 0 1rem;
    position: sticky;
    top: 0;
    display: grid;
    transform: translateY(50%);
    /* place-items: center; */
  
    > ul {
      grid-auto-flow: column;
  
      > li {
        margin: 0 0.5rem;
  
        a,
        .dropdown__title {
          text-decoration: none;
          text-align: left;
          display: inline-block;
          color: #fff;
          @include fluid-type(49rem, 88rem, .2rem, 1.125rem);
          min-height: 0vw;
  
          &:focus {
            outline: none;
          }
        }
  
        > a,
        .dropdown__title {
          padding: 0.4rem 0.5rem;
          border-top: 2px solid transparent;
          border-bottom: 2px solid transparent;
          transition: $transition;
          font-weight: bold;
  
          &:hover,
          &:focus {
            border-top-color: $white;
            border-bottom-color: $white;
            color: $white;
          }
        }
      }
    }
  
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: grid;
  
      li {
        padding: 0;
        font-family: 'Roboto Slab', serif;
      }
    }
  }
  
  .dropdown {
    position: relative;
  
    .dropdown__title {
      display: inline-flex;
      align-items: center;
      &:focus-visible {
        outline: 1px solid $white;
        box-shadow: 0 0 0px 1px #1D5FCC;
        border-radius: 3px;
      }
      &:after {
        content: "";
        border: 0.35rem solid transparent;
        border-top-color: rgba(#fff, 1);
        margin-left: 0.25em;
        transform: translateY(0.15em);
      }
    }
  
    .dropdown__menu {
      position: absolute;
      min-width: 15ch;
      left: 0;
      top: calc(100% - 0rem);
      transition: $transition;
      /* transform: rotateX(-90deg) translateX(0); */
      transform-origin: top center;
      visibility: hidden;
      opacity: 0.3;
      padding: 15px;
      background-color: $primary-color;
      border-radius: 0;
      box-shadow: 0 0.15em 0.25em rgba(black, 0.25);
      z-index: 999;

      li {
  
        a {
          color: $white;
          display: block;
          padding: 15px 0.5em;
          opacity: 0;
          font-family: "Open Sans", sans-serif;
          white-space: nowrap;
          transition: 180ms all 0ms ease-in;
    
          &:hover {
            background-color: rgba($black, 0.22);
            border-radius: 4px;
            text-decoration: underline;
            text-decoration-thickness: 2px;
          }
    
          &:focus {
            outline: none;
            background-color: rgba($black, 0.22);
            border-radius: 4px;
          }

          &:focus-visible {
            outline: 1px solid $white;
            box-shadow: 0 0 0px 1px #1D5FCC;
            border-radius: 3px;
          }
        }
      }
    }
  
    &:hover,
    &:focus-within {
      .dropdown__title {
        border-top-color: $white;
        border-bottom-color: $white;
      }
  
      .dropdown__menu {
        opacity: 1;
        transform: rotateX(0) translateX(0);
        visibility: visible;
  
        a {
          opacity: 1;
        }
      }
  
      &:after {
        opacity: 1;
      }
    }
  }
}